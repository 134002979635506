// @ts-nocheck

import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import 'animate.css/animate.min.css';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Title from '../../components/Title';
import heroImage from '../../images/contact-us-background.jpg';
import Footer from '../../components/Footer';
import KeystoneForm from '../../components/KeyStoneForm';
import enFlag from '../../images/states-flag.png';
import zhFlag from '../../images/china-flag.png';
import frFlag from '../../images/france-flag.png';
import esFlag from '../../images/spain-flag.png';
import itFlag from '../../images/italy-flag.png';
import ptFlag from '../../images/portugal-flag.png';
import deFlag from '../../images/germany-flag.png';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const LanguageContainer = styled.div`
  margin-top: 3rem;
`;

const LanguageButton = styled.button.attrs({
  className: 'btn',
  type: 'button',
})`
  & {
    border: 1px solid black;
    color: black;
    diplay: block;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0px;
  }
  & img {
    padding-right: 0.3rem;
  }
`;

const H1 = styled.h1`
  color: #004165;
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
`;

const ContactUsStudentsPage = () => {
  const [currentLang, setCurrentLang] = useState('en');
  const connectorIds = {
    en: 'f233e9d03f5c11e9af0985818cd32c4f',
    zh: 'a8cdd3c0e9a711e98855456e12ad6e74',
    fr: 'fee59d30e9a611e9a2e759ba14fb24dd',
    es: '3130b520e9a711e99afd4f550b3f03e3',
    it: '57afb4f0e9a711e9980aa5d2ed493627',
    de: '6b82bd20e9a711e9ab590b089dc56eb5',
    pt: '8ddd2c40e9a711e9b1700fe7643dd5fe',
  };

  useEffect(() => {}, [currentLang]);

  return (
    <>
      <Layout>
        <SEO title="Contact Us" />
        <Wrapper className="container-fluid">
          <Title imageUrl={heroImage} position="top" text="Contact Us - Students" centered />
          <StyledRow>
            <Col lg={7} md={11}>
              <Row>
                <Col lg={9} md={9}>
                  <KeystoneForm keystoneId={connectorIds[currentLang]} />
                </Col>
                <Col lg={3} md={3}>
                  <LanguageContainer>
                    <H1>Choose Form Language</H1>
                    <LanguageButton onClick={() => setCurrentLang('en')}>
                      <img src={enFlag} alt="United States Flag" /> English
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('zh')}>
                      <img src={zhFlag} alt="China Flag" /> Chinese
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('fr')}>
                      <img src={frFlag} alt="French Flag" /> French
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('es')}>
                      <img src={esFlag} alt="Spanish Flag" /> Spanish
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('it')}>
                      <img src={itFlag} alt="Italian Flag" /> Italian
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('de')}>
                      <img src={deFlag} alt="Germany Flag" /> German
                    </LanguageButton>
                    <LanguageButton onClick={() => setCurrentLang('pt')}>
                      <img src={ptFlag} alt="Portugal Flag" /> Portuguese
                    </LanguageButton>
                  </LanguageContainer>
                </Col>
              </Row>
            </Col>
          </StyledRow>
          <Footer />
        </Wrapper>
      </Layout>
    </>
  );
};

export default injectIntl(ContactUsStudentsPage);

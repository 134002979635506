// @ts-nocheck

import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import 'animate.css/animate.min.css';

// eslint-disable-next-line react/prop-types
const KeystoneForm = ({ keystoneId }) => (
  <>
    <Helmet>
      <script type="text/javascript">
        {`
          window.FormConnector_settings = window._FormConnector_settings || {};

          FormConnector_settings.uuid = '${keystoneId}';

          (function (w, d, o, e, p) {
              w._FormConnector_settings_object = o;

              e = d.createElement('script');
              e.async = 1;
              e.src = 'https://formconnector.com/form/formconnector.js';

              p = d.getElementsByTagName('head')[0];
              p.appendChild(e, p)
          })(window, document, 'FormConnector_settings');
        `}
      </script>
    </Helmet>
    <div id="formconnector_target" />
  </>
);

export default injectIntl(KeystoneForm);
